<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between">
        <div>
          <button
            class="compact ui button"
            @click="refreshCommon"
          >
            <refresh-cw-icon
              size="12"
              class="custom-class"
              :stroke-width="3"
            />
            Tải lại
          </button>
          <button
            class="compact ui btn-primary button"
            :class="{disabled: selectedRows && selectedRows.length === 0}"
            @click="processMultipleDoc"
          >
            <corner-down-right-icon
              size="12"
              class="custom-class"
              :stroke-width="3"
            />
            Tiếp nhận
          </button>
        </div>
        <div>
          <filter-icon
            size="20"
            class="cursor-pointer mb-1"
            :stroke-width="2"
            @click="showFormFilter"
          />
        </div>
      </div>

      <advanced-search
        v-show="formFilterShow"
        ref="formFilter"
        class="mt-2"
        @advanced-search="filterDanhSachHoSo"
      />
      <good-table
        ref="hoSo"
        class="mt-1"
        :columns="columns"
        :rows="rows"
        :total="tongSoHoSo"
        @selected-item="onSelectRow"
        @column-filter="filterDanhSachHoSo"
        @update-item="onUpdate"
        @delete-item="setItemUpdate"
        @page-change="pageChange"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <div
            v-if="props.props.column.field === 'tenTrangThai'"
            class="custom-column"
          >
            <div>{{ formatDateTime(props.props.row.ngayNop) }}</div>
            <div>{{ formatDateTime(props.props.row.ngayHenTraKq) }}</div>
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="props.props.row.tenLoaiTrangThai" />
            <!--eslint-enable-->
            <div
              v-if="props.props.row.thoiGianDenHanTraKQ"
              :class="props.props.row.thoiGianDenHanTraKQ.indexOf('Quá hạn') >= 0 ? 'text-red-cor' : 'text-green-cor'"
            >
              {{ props.props.row.thoiGianDenHanTraKQ }}
            </div>
          </div>
          <span v-if="props.props.column.field == 'code'">
            <a
              href="javascript:;"
              @click="detailHoSo(props.props.row)"
            >{{ `${props.props.row.code}` }}</a>
            <div>
              <cloud-icon
                size="15"
                class="custom-class"
              /> {{ props.props.row.kieuTiepNhan }}
            </div>
            <div v-if="props.props.row.dichVuChuyenPhatKetQua">
              <send-icon
                size="15"
                class="custom-class"
              /> Dịch vụ chuyển phát kết quả
            </div>
          </span>
          <div
            v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
            class="custom-column"
          >
            <div v-if="props.props.row.tenTrangThai && !props.props.row.tenTrangThai.indexOf('Kết thúc')">
              <div class="text-yellow-cor">
                {{ props.props.row.tenTrangThai }}
              </div>

            </div>
            <div v-else>
              <div>{{ formatDateTime(props.props.row.thoiGianBatDauTrangThai) }}</div>
              <div>{{ formatDateTime(props.props.row.thoiGianKetThucTrangThai) }}</div>
              <div class="text-yellow-cor">
                {{ props.props.row.tenTrangThai }}
              </div>
              <div
                v-if="props.props.row.thoiGianDenHanKetThucTrangThai"
                :class="props.props.row.thoiGianDenHanKetThucTrangThai.indexOf('Còn hạn') >= 0 ? 'text-green-cor' : 'text-red-cor'"
              >
                {{ props.props.row.thoiGianDenHanKetThucTrangThai }}
              </div>
            </div>

          </div>
          <div v-else-if="props.props.column.field === 'chuHoSo_HoVaTen'">
            <div v-if="props.props.row.chuHoSo_HoVaTen">
              <user-icon size="14" /> {{ props.props.row.chuHoSo_HoVaTen }}
            </div>
            <div
              v-if="props.props.row.chuHoSo_DiaChiDayDu"
              class="text-top word-break"
            >
              <map-pin-icon size="14" /> {{ props.props.row.chuHoSo_DiaChiDayDu }}
            </div>
          </div>
          <div v-else-if="props.props.column.field === 'tenThuTucHanhChinh'">
            {{ props.props.row.tenThuTucHanhChinh }}
          </div>
        </template>
        <template
          slot="custom-filter"
          slot-scope="props"
        >
          <treeselect
            v-if="props.props.column.field === 'tenThuTucHanhChinh'"
            id="linhVuc"
            ref="tenThuTucHanhChinh"
            v-model="filter.dataTTHCId"
            v-format-v-select
            :default-options="danhSachThuTucHanhChinh"
            placeholder="Chọn thủ tục hành chính"
            :limit="0"
            multiple
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            loading-text="Đang tìm kiếm"
            :normalizer="normalizer"
            :match-keys="['label', 'label2']"
            class="vtreeselect-chooser"
            :load-options="onQueryChange"
            :async="true"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="$refs.tenThuTucHanhChinh.resetSearchQuery(); nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          >
            <label
              slot="option-label"
              slot-scope="{ node, shouldShowCount, count, labelClassName }"
              :class="labelClassName"
              :title="node.label"
            >
              {{ node.label }}
            </label>
          </treeselect>
          <treeselect
            v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
            v-model="filter.trangThaiConHanQuaHan"
            v-format-v-select
            class="vtreeselect-chooser"
            :options="danhSachThoiHan"
            :limit="0"
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            placeholder="Chọn thời hạn"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          />
          <treeselect
            v-else-if="props.props.column.field === 'tenTrangThai'"
            v-model="filter.loaiTrangThaiConHanQuaHan"
            v-format-v-select
            class="vtreeselect-chooser"
            :options="danhSachThoiHan"
            :limit="0"
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            placeholder="Chọn thời hạn"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          />
        </template>
        <template
          slot="actions"
          slot-scope="props"
        >
          <div class="text-center">
            <eye-icon
              v-b-tooltip.hover.v-secondary
              title="Chi tiết"
              size="16"
              class="custom-class mr-1 cursor-pointer"
              @click="detailHoSo(props.props.row)"
            />
          </div>

        </template>
      </good-table>
    </b-card>
    <xu-ly-ho-so
      ref="xuLyHoSo"
      @reload-page="filterDanhSachHoSo()"
    />
  </div>

</template>
<script>
import {
  BCard,
  VBTooltip,
} from 'bootstrap-vue'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import GoodTable from '@/components/GoodTable.vue'
import AdvancedSearch from '@/components/AdvancedSearch.vue'
import XuLyHoSo from '@/modules/xu-ly-ho-so/components/common/XuLyHoSo.vue'
import END_POINTS from '@/api/endpoints'
import { formatDateTime, removeDiacritical } from '@/utils/index'
import _debounce from 'lodash/debounce'
import {
  FilterIcon,
  EyeIcon,
  CloudIcon,
  SendIcon,
  UserIcon,
  CornerDownRightIcon,
  RefreshCwIcon,
  MapPinIcon,
} from 'vue-feather-icons'
import addCssSemantic from '@/mixins/mixins'

export default {
  components: {
    GoodTable,
    BCard,
    EyeIcon,
    XuLyHoSo,
    UserIcon,
    CornerDownRightIcon,
    RefreshCwIcon,
    MapPinIcon,
    FilterIcon,
    CloudIcon,
    SendIcon,
    AdvancedSearch,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      faMagnifyingGlass,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã biên nhận',
          field: 'code',
          width: '200px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          width: 'auto',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          width: '250px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'tenTrangThai',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Chi tiết',
          field: 'tenLoaiTrangThai',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '100px',
        },
      ],
      tongSoHoSo: 0,
      selectedNumber: 0,
      rows: [],
      selectedRows: [],
      filter: {
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: '',
        maHoSo: '',
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiConHanQuaHan: null,
        loaiTrangThaiConHanQuaHan: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      },
      danhSachThoiHan: [
        {
          id: 1,
          label: 'Còn hạn',
        },
        {
          id: 2,
          label: 'Quá hạn',
        },
      ],
      formFilterShow: false,
      danhSachThuTucHanhChinh: [],
    }
  },
  created() {
    this.filterDanhSachHoSo()
    this.getDanhSachThuTucHanhChinh()
  },
  methods: {
    formatDateTime,
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.danhSachThuTucHanhChinh.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    getDanhSachThuTucHanhChinh() {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachThuTucHanhChinh = response.data.data
        }
      })
    },
    detailHoSo(data) {
      this.$router.push({ path: '/ho-so/chi-tiet', query: { id: data.id, name: this.$route.name } })
    },
    processMultipleDoc() {
      if (this.selectedRows && this.selectedRows.length !== 0) {
        this.$refs.xuLyHoSo.show(this.selectedRows)
      }
    },
    onSelectRow(rows) {
      this.selectedRows = rows
      this.selectedNumber = rows.length
    },
    filterDanhSachHoSo(param = {}) {
      this.filter = {
        ...this.filter,
        ...param,
      }
      let payload = {
        ...this.filter,
        trangThaiConHanQuaHan: this.filter.trangThaiConHanQuaHan || 0,
        loaiTrangThaiConHanQuaHan: this.filter.loaiTrangThaiConHanQuaHan || 0,
      }
      if (this.$refs.hoSo) {
        payload = {
          ...payload,
          ...this.$refs.hoSo.getCurrentPage(),
        }
      }
      if (this.filter.trangThaiConHanQuaHan) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.loaiTrangThaiConHanQuaHan) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.dataTTHCId.length) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.$refs.formFilter) {
        payload = {
          ...payload,
          ...this.$refs.formFilter.getFormFilter(),
        }
      }
      if (param.chuHoSo_HoVaTen) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          chuHoSo: param.chuHoSo_HoVaTen ? param.chuHoSo_HoVaTen.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.code) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          maHoSo: param.code ? param.code.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.chuHoSo_DiaChiDayDu) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          diaChiChuHoSo: param.chuHoSo_DiaChiDayDu ? param.chuHoSo_DiaChiDayDu.replace(/\s+/g, ' ').trim() : '',
        }
      }
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_BO_SUNG.DANH_SACH_HO_SO_BO_SUNG, this.$trimObject({
        ...payload,
      }), false).then(response => {
        if (response.data && response.data.succeeded) {
          this.rows = response.data.data
          this.tongSoHoSo = response.data.totalCount || 0
          this.$refs.hoSo.resetCurrentPage(payload.pageNumber)
        }
      })
    },
    pageChange() {
      const data = this.$refs.hoSo.getCurrentPage()
      this.payload = {
        ...this.filter,
        trangThaiConHanQuaHan: this.filter.trangThaiConHanQuaHan || 0,
        loaiTrangThaiConHanQuaHan: this.filter.loaiTrangThaiConHanQuaHan || 0,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios
        .post(
          END_POINTS.TIEP_NHAN_HO_SO_BO_SUNG.DANH_SACH_HO_SO_BO_SUNG, this.payload, false,
        )
        .then(response => {
          if (response.data && response.data.succeeded) {
            this.rows = response.data.data
            this.tongSoHoSo = response.data.totalCount || 0
          }
        })
    },
    onUpdate(item) {
      this.form = {
        id: item.id,
        mahoSo: item.mahoSo,
        tenhoSo: item.tenhoSo,
        loaihoSoId: item.loaihoSoId,
        status: item.status,
      }
    },
    setItemUpdate(item) {
      this.selectedRows = [item]
    },
    onDelete() {},
    showFormFilter() {
      this.formFilterShow = !this.formFilterShow
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
    resetFilter() {
      this.filter = {
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: null,
        maHoSo: null,
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiConHanQuaHan: null,
        loaiTrangThaiConHanQuaHan: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.filterDanhSachHoSo()
      })
    },
    refreshCommon() {
      if (this.$refs.formFilter) {
        this.$refs.formFilter.resetForm()
      }
      this.$refs.hoSo.$refs.vbTables.reset()
      this.resetFilter()
      this.filterDanhSachHoSo()
    },
  },
}
</script>
<style lang="scss" scoped>
.input-icon {
  padding: 8px 0 8px 8px;
  border: 1px solid #d8d6de;
  border-right: none;
}

.input-group-icon {
  border-left: none;
}

.router-link-color {
  a {
    color: white;
  }
}

.custom-column {
  p {
    margin-bottom: 0;
  }
}
.text-top{
    margin-top:6px;
  }
</style>
