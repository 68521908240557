<template>
  <yeu-cau-bo-sung />
</template>
<script>
import YeuCauBoSung from '@/modules/xu-ly-ho-so/components/pages/yeu-cau-bo-sung/YeuCauBoSung.vue'

export default {
  components: {
    YeuCauBoSung,
  },
}
</script>
